import React from 'react'
import { urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  return (
    <div className={styles.header}>
      <img className={styles.headerBg} src={sanityImageUrl(header.image)} alt={header.image?.caption} />
      <div className={styles.wrap}>
        {header.rating?.url && (
          <Link to={header.rating.url} className={styles.rating}>
            {header.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(header.rating.starsImage)} alt={header.rating?.text}/>}
            {header.rating.text && <span className={styles.ratingText}>{header.rating.text}</span>}
          </Link>
        )}
        <h2 className={styles.title}>{header.title}</h2>
        <p className={styles.text}>{header.text}</p>
        <a href={header.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>{header.buttonText}</a>
      </div>
    </div>
  )
}
