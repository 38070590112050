import React from 'react'
import { urlWithSearchParamsHandler, prepareParagraph, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ stats }) => {
  if (!stats || stats.isHide) return null

  return (
    <div className={styles.stats}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{stats.title}</h2>
          <a href={stats.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
            {stats.buttonText}
          </a>
        </div>
        <div className={styles.items}>
          {stats.items && stats.items.map(item => (
            <div className={styles.item} key={item._key}>
              <p className={styles.ingredient}>{item.ingredient}</p>
              <p className={styles.value}>{item.value}</p>
              <p className={styles.description}>{prepareParagraph(item.description)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}