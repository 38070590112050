import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/format'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Header from '../components/HairPortfolioPage/Header'
import Protocols from '../components/HairPortfolioPage/Protocols'
import Stats from '../components/HairPortfolioPage/Stats'
import Explain from '../components/HairPortfolioPage/Explain'
import Table from '../components/HairPortfolioPage/Table'
import Reviews from '../components/HairPortfolioPage/Reviews'
import Benefits from '../components/HairPortfolioPage/Benefits'
import Save from '../components/HairPortfolioPage/Save'
import Benefits2 from '../components/HairPortfolioPage/Benefits2'
import Faq from '../components/HairPortfolioPage/Faq'
import References from '../components/HairPortfolioPage/References'
import FoodDrug from '../components/HairPortfolioPage/FoodDrug'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Protocols protocols={data.protocols} />,
      priority: data.protocols?.priority || 99
    },
    {
      render: <Stats stats={data.stats} />,
      priority: data.stats?.priority || 99
    },
    {
      render: <Explain explain={data.explain} />,
      priority: data.explain?.priority || 99
    },
    {
      render: <Table table={data.table} />,
      priority: data.table?.priority || 99
    },
    {
      render: <Reviews reviews={data.reviews} />,
      priority: data.reviews?.priority || 99
    },
    {
      render: <Benefits benefits={data.benefits} />,
      priority: data.benefits?.priority || 99
    },
    {
      render: <Save save={data.save} />,
      priority: data.save?.priority || 99
    },
    {
      render: <Benefits2 benefits2={data.benefits2} />,
      priority: data.benefits2?.priority || 99
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99
    },
    {
      render: <References references={data.references} />,
      priority: data.references?.priority || 99
    },
    {
      render: <FoodDrug foodDrug={data.foodDrug} />,
      priority: data.foodDrug?.priority || 99
    }
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      {data.productSchemas && data.productSchemas.map(schema => <ProductSchema {...schema} />)}
      {(data?.faq?.faqList?.items || data?.faq?.items) && (
        <FaqSchema questions={data?.faq?.faqList?.items || data?.faq?.items} />
      )}
      <Header header={data.header} />
      {components.map(component => component.render)}
    </Layout>
  )
}
