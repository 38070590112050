import React from 'react'
import styles from './style.module.sass'

export default ({ foodDrug }) => {
  if (!foodDrug || foodDrug.isHide) return null

  return (
    <div className={styles.foodDrug}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{foodDrug.text}</h2>
      </div>
    </div>
  )
}